import { Box, CircularProgress, MenuItem, Select, Stack, Typography, styled } from '@mui/material';
import { ProductTenor } from '@protos/product';
import { NumberInput } from '@shared/components/NumberInput';
import { useDashboardContext } from '@shared/contexts/DashboardContext';
import { useTradingTenorContext } from '@shared/contexts/TradingTenorProvider';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { useUserProductsAndTenorsContext } from '@shared/contexts/UserProductsAndTenorsProvider';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TradingButtons } from './TradingButtons';
import { TradingPanel, useTradingContext } from './TradingContext';
import { DEFAULT_TRADE_SIZE, MIN_SIZE_VALUE, TradingProducts } from './constants';

const TradingSelect = styled(Select)(({ theme }) => ({
  fontSize: 11,
  height: 20,
  width: '100%',
  borderRadius: 0,
  '& .MuiSelect-select': {
    padding: 0,
    paddingLeft: 4,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20,
    padding: 0,
  },
}));

const CustomTradingMenuProps = { PaperProps: { sx: { maxHeight: 100, width: 70, borderRadius: 0 } } };
const CustomTradingMenuItemProps = { fontSize: 11, padding: 0, paddingLeft: 1 };

interface TradingPanelProps {
  id: string;
  payload: TradingPanel;
  isWidgetOpen: boolean;
}

export const TradingPanelComponent = ({ id, payload, isWidgetOpen }: TradingPanelProps) => {
  const sizeInputRef = useRef<HTMLDivElement>(null);
  const { editWidgetPayloadById } = useDashboardContext();
  const user = useUserContext();
  const { tenors } = useUserProductsAndTenorsContext();
  const monthlyTenors = useMemo(() => tenors.filter(tenor => tenor.frequency === 'monthly').slice(2), [tenors]);
  const { subscriptionSymbol, setSubscriptionSymbol, setPrices, unsubscribeSymbol } = useTradingContext();
  const { getAllUsedTenors, setWidgetTenorMap, widgetTenorMap } = useTradingTenorContext();

  //Set up trading form for the widget
  const {
    selectedProduct: incomingSelectedProduct,
    selectedTenor: incomingSelectedTenor,
    tradeSize: incomingTradeSize,
    selectedTradingAccount: incomingSelectedTradingAccount,
  } = payload;

  const initialSelectedTenor = useMemo(() => {
    if (!monthlyTenors.length) return '';

    const allUsedTenors = getAllUsedTenors(id, false) as ProductTenor[];
    const availableTenors = monthlyTenors.filter(tenor => !allUsedTenors.find(t => t.code === tenor.code));
    if (!availableTenors.length) return monthlyTenors[0].code;

    return availableTenors[0].code;
  }, [getAllUsedTenors, monthlyTenors]);
  const [tradingForm, setTradingForm] = useState<TradingPanel>({
    selectedProduct: incomingSelectedProduct || TradingProducts[0],
    selectedTenor: incomingSelectedTenor || initialSelectedTenor,
    tradeSize: incomingTradeSize || DEFAULT_TRADE_SIZE,
    selectedTradingAccount: incomingSelectedTradingAccount,
  });

  const [validTenors, setValidTenors] = useState<ProductTenor[]>(monthlyTenors);
  const [validBackTenors, setValidBackTenors] = useState<ProductTenor[]>(monthlyTenors);

  //Set up trade limits for the user and check if the trade size exceeds the limit
  const [userTradeLimits, setUserTradeLimits] = useState<Record<string, number>>({});
  const [isTradeLimitBreached, setIsTradeLimitBreached] = useState<boolean>(false);
  const showUserInputError = useMemo(
    () => isTradeLimitBreached || tradingForm.tradeSize <= 0 || tradingForm.tradeSize === undefined,
    [tradingForm, isTradeLimitBreached]
  );

  const [isLoadingPanel, setIsLoadingPanel] = useState<boolean>(true);
  const productTradingLimit = useMemo(() => userTradeLimits[tradingForm.selectedProduct.toLocaleLowerCase()], [userTradeLimits, tradingForm]);

  //Form Functions
  const onTradingProductChange = useCallback(
    (value: string) => {
      setIsLoadingPanel(true);
      const { selectedProduct, selectedTenor } = tradingForm;
      if (!TradingProducts.includes(value) || value === selectedProduct) return;

      const isNewProductSpread = value.toLocaleLowerCase().includes('spr');
      const isCurrentTenorSpread = typeof selectedTenor === 'object';
      const allUsedTenors = getAllUsedTenors(id, isNewProductSpread);

      if (isNewProductSpread && !isCurrentTenorSpread) {
        const leftTenorCode = monthlyTenors[0].code;
        const usedSpreadProductTenors = (allUsedTenors as { left: ProductTenor; right: ProductTenor }[]).filter(t => t.left.code === leftTenorCode);
        let rightTenorCode = monthlyTenors[1].code;
        if (usedSpreadProductTenors.length) {
          const usedRightTenors = usedSpreadProductTenors.map(t => t.right.code);
          const availableRightTenors = monthlyTenors.filter(tenor => !usedRightTenors.includes(tenor.code) && tenor.code !== leftTenorCode);
          rightTenorCode = availableRightTenors[0].code;
        }
        setTradingForm((prev: TradingPanel) => ({ ...prev, selectedProduct: value, selectedTenor: { left: leftTenorCode, right: rightTenorCode } }));
      } else if (!isNewProductSpread && isCurrentTenorSpread) {
        const usedNonSprTenors = allUsedTenors as ProductTenor[];
        const availableNonSprTenors = monthlyTenors.filter(tenor => !usedNonSprTenors.find(t => t.code === tenor.code));
        setTradingForm((prev: TradingPanel) => ({ ...prev, selectedProduct: value, selectedTenor: availableNonSprTenors[0].code }));
      }
    },
    [tradingForm, getAllUsedTenors, monthlyTenors]
  );

  const onTenorChange = useCallback(
    (value: string, key?: 'left' | 'right') => {
      setIsLoadingPanel(true);
      const { selectedTenor } = tradingForm;
      const isSpreadTenorChange = key !== undefined;
      if (!isSpreadTenorChange) {
        setTradingForm((prev: TradingPanel) => ({ ...prev, selectedTenor: value }));
      } else {
        if (selectedTenor[key] === value) return;
        else if (selectedTenor && typeof selectedTenor === 'object') {
          if (key === 'right') {
            const newTenor = { left: selectedTenor.left, right: value };
            setTradingForm((prev: TradingPanel) => ({ ...prev, selectedTenor: newTenor }));
          } else {
            const allUsedTenors = getAllUsedTenors(id, true);
            const usedSpreadProductTenors = (allUsedTenors as { left: ProductTenor; right: ProductTenor }[]).filter(t => t.left.code === value);
            const updatedRightTenors = usedSpreadProductTenors.map(t => t.right.code);
            const availableRightTenors = monthlyTenors.filter(tenor => !updatedRightTenors.includes(tenor.code) && tenor.code !== value);

            const newTenor = { left: value, right: availableRightTenors[0].code };
            setTradingForm((prev: TradingPanel) => ({ ...prev, selectedTenor: newTenor }));
          }
        }
      }
    },
    [tradingForm, getAllUsedTenors, monthlyTenors]
  );

  const onTradeSizeChange = useCallback(
    debounce((_, newValue) => {
      setTradingForm((prev: TradingPanel) => ({ ...prev, tradeSize: newValue }));

      if (newValue > productTradingLimit) {
        setIsTradeLimitBreached(true);
      } else if (isTradeLimitBreached) {
        setIsTradeLimitBreached(false);
      }
    }, 350),
    [isTradeLimitBreached, productTradingLimit]
  );

  //Set up trading limits from user context
  useEffect(() => {
    if (user && user.trade_limits.length) {
      const tradeLimits = user.trade_limits;
      setUserTradeLimits(tradeLimits.reduce((acc, limit) => ({ ...acc, [limit.symbol.toLocaleLowerCase()]: limit.limit }), {}));
    }
  }, [user]);

  //Highlight Size input field on widget load
  useEffect(() => {
    if (sizeInputRef.current) {
      sizeInputRef.current?.focus();
    }
  }, [sizeInputRef.current]);

  //Save widget based on user input in trading form and update widgetTenorMap
  useEffect(() => {
    const { selectedProduct, selectedTenor, tradeSize } = tradingForm;
    if (!selectedProduct || !selectedTenor || !tradeSize) return;

    const rfqSubscriptionSymbol =
      typeof selectedTenor === 'string'
        ? `${selectedProduct}${selectedTenor}-${tradeSize}`
        : `${selectedProduct.split('spr')[0]}${selectedTenor.left}-${selectedProduct.split('spr')[0]}${selectedTenor.right}-${tradeSize}`;

    const isSpread = typeof selectedTenor === 'object';
    const tenorsUsed = isSpread
      ? [
          {
            left: monthlyTenors.find(tenor => tenor.code === selectedTenor.left),
            right: monthlyTenors.find(tenor => tenor.code === selectedTenor.right),
          },
        ]
      : [monthlyTenors.find(tenor => tenor.code === selectedTenor)];
    const nonUndefinedTenorsUsed = tenorsUsed.filter(tenor => tenor !== undefined) as ProductTenor[];

    setWidgetTenorMap(prev => ({ ...prev, [id]: { isSpread, tenorsUsed: nonUndefinedTenorsUsed } }));
    setPrices({ bid: undefined, ask: undefined });

    const currentSubscriptionSymbolElements = subscriptionSymbol.split('-');
    if (currentSubscriptionSymbolElements.length !== rfqSubscriptionSymbol.split('-').length) {
      unsubscribeSymbol(subscriptionSymbol);
    } else {
      if (currentSubscriptionSymbolElements.length === 3 && typeof selectedTenor !== 'string') {
        if (
          `${selectedProduct.split('spr')[0]}${selectedTenor.left}` !== currentSubscriptionSymbolElements[0] ||
          `${selectedProduct.split('spr')[0]}${selectedTenor.right}` !== currentSubscriptionSymbolElements[1]
        )
          unsubscribeSymbol(subscriptionSymbol);
      } else if (`${selectedProduct}${selectedTenor}` !== currentSubscriptionSymbolElements[0]) {
        unsubscribeSymbol(subscriptionSymbol);
      }
    }

    setSubscriptionSymbol(rfqSubscriptionSymbol);

    editWidgetPayloadById(id, { selectedProduct, selectedTenor, tradeSize, selectedTradingAccount: incomingSelectedTradingAccount });

    if (isLoadingPanel) setIsLoadingPanel(false);
  }, [tradingForm, id, subscriptionSymbol, setWidgetTenorMap, monthlyTenors]);

  // Update Tenors based on WidgetTenorMap changes
  useEffect(() => {
    const currentWidgetTenorMap = widgetTenorMap[id];
    if (!currentWidgetTenorMap) return;

    if (currentWidgetTenorMap.isSpread) {
      const allUsedTenors = getAllUsedTenors(id, true) as { left: ProductTenor; right: ProductTenor }[];
      const currentWidgetTenor = currentWidgetTenorMap.tenorsUsed[0] as { left: ProductTenor; right: ProductTenor };
      if (!currentWidgetTenor) return;

      const usedSpreadProductTenors = allUsedTenors.filter(t => t.left.code === currentWidgetTenor.left.code);
      const usedRightTenorCodes = usedSpreadProductTenors.map(t => t.right.code);
      const availableRightTenors = monthlyTenors.filter(
        tenor => !usedRightTenorCodes.includes(tenor?.code) && tenor?.code !== currentWidgetTenor?.left?.code
      );

      if (validTenors.length !== monthlyTenors.length) setValidTenors(monthlyTenors);
      setValidBackTenors(availableRightTenors);
    } else {
      const allUsedTenors = getAllUsedTenors(id, false);
      const usedTenors = allUsedTenors as ProductTenor[];
      const availableNonSprTenors = monthlyTenors.filter(tenor => !usedTenors.find(t => t.code === tenor.code));
      setValidTenors(availableNonSprTenors);
    }
  }, [widgetTenorMap, id, monthlyTenors, getAllUsedTenors, validTenors]);

  // Clean Widget ID from Tenor Map when widget is closed
  useEffect(() => {
    if (!isWidgetOpen) {
      setWidgetTenorMap(prev => {
        const newWidgetTenorMap = { ...prev };
        delete newWidgetTenorMap[id];
        return newWidgetTenorMap;
      });
      unsubscribeSymbol(subscriptionSymbol);
    }
  }, [isWidgetOpen, widgetTenorMap]);

  if (isLoadingPanel)
    return (
      <Stack direction="column" flex={1} alignItems="center" justifyContent="center">
        <Typography fontSize={11} color="textSecondary" tabIndex={-1}>
          Loading...
        </Typography>
      </Stack>
    );

  return (
    <Stack display="flex" flexDirection="column" flex={1} alignItems="center" padding={1} justifyContent="space-between">
      <Stack display="flex" gap={1} width="100%">
        <TradingSelect
          size="small"
          value={tradingForm.selectedProduct}
          onChange={(event: any) => onTradingProductChange(event.target.value)}
          MenuProps={CustomTradingMenuProps}
          tabIndex={-1}
        >
          {TradingProducts.map(option => (
            <MenuItem key={option} value={option} sx={CustomTradingMenuItemProps}>
              {option.toLocaleUpperCase()}
            </MenuItem>
          ))}
        </TradingSelect>
        {tradingForm.selectedTenor && typeof tradingForm.selectedTenor === 'object' ? (
          <Box display="flex" width="100%">
            <TradingSelect
              size="small"
              value={tradingForm.selectedTenor.left}
              onChange={(event: any) => onTenorChange(event.target.value, 'left')}
              MenuProps={CustomTradingMenuProps}
              tabIndex={-1}
            >
              {validTenors.map(option => (
                <MenuItem key={option.code} value={option.code} sx={CustomTradingMenuItemProps}>
                  {option.display.toLocaleUpperCase()}
                </MenuItem>
              ))}
            </TradingSelect>
            <TradingSelect
              size="small"
              value={tradingForm.selectedTenor.right}
              onChange={(event: any) => onTenorChange(event.target.value, 'right')}
              MenuProps={CustomTradingMenuProps}
              tabIndex={-1}
            >
              {validBackTenors.map(option => (
                <MenuItem key={option.code} value={option.code} sx={CustomTradingMenuItemProps}>
                  {option.display.toLocaleUpperCase()}
                </MenuItem>
              ))}
            </TradingSelect>
          </Box>
        ) : (
          <TradingSelect
            size="small"
            value={tradingForm.selectedTenor}
            onChange={(event: any) => onTenorChange(event.target.value)}
            MenuProps={CustomTradingMenuProps}
            tabIndex={-1}
          >
            {validTenors.map(option => (
              <MenuItem key={option.code} value={option.code} sx={CustomTradingMenuItemProps}>
                {option.display.toLocaleUpperCase()}
              </MenuItem>
            ))}
          </TradingSelect>
        )}
      </Stack>
      <Stack alignItems="center">
        <NumberInput
          ref={sizeInputRef}
          label="RFQ Lots"
          value={tradingForm.tradeSize}
          min={MIN_SIZE_VALUE}
          onChange={onTradeSizeChange}
          tabIndex={1}
          error={showUserInputError}
          allowClearable
          sizeSmall
          sx={{ width: '100%' }}
        />
        {isTradeLimitBreached && (
          <Typography fontSize={11} color="error" tabIndex={-1}>
            Trade size exceeds trading limit
          </Typography>
        )}
      </Stack>
      {subscriptionSymbol ? (
        <TradingButtons rfqSubscriptionSymbol={subscriptionSymbol} isDisabled={showUserInputError} tradingAccount={incomingSelectedTradingAccount} />
      ) : (
        <CircularProgress size={10} />
      )}
    </Stack>
  );
};
